$primary:       #D84B52 !default;
$secondary:     #B13B41 !default;
$white:         #fff !default;
$gray-100:      #f8f9fa !default;
$gray-200:      #e9ecef !default;
$gray-300:      #dee2e6 !default;
$gray-400:      #ced4da !default;
$gray-500:      #adb5bd !default;
$gray-600:      #83817F !default;
$gray-700:      #495057 !default;
$gray-800:      #34302A !default;
$gray-900:      #26282B !default;
$black:         #0D0D0D !default;

$theme-colors: (
    "new-color":     #cc00ff,
    "new-gradient":  #ff0055
) !default;

[class*="new-gradient"] {
    background-image: linear-gradient(90deg, rgba(26, 215, 249, 0) 0%, #18d9f9 100%);
}

$body-bg:                   $white !default;
$body-color:                #586570 !default;

$link-color:                #4C97D3 !default;
$link-decoration:           none !default;
$link-hover-color:          #3D7CAE !default;
$link-hover-decoration:     underline !default;

$border-width:              1px !default;
$border-color:              #B4B4B4 !default;

$border-radius:             8px !default;
$border-radius-lg:          8px !default;
$border-radius-sm:          8px !default;

@import url("https://use.typekit.net/jrs4bfr.css");


$font-family-base:          'proxima-nova', sans-serif !default;
$headings-font-family:      'temeraire', serif !default;
$display-font-family:       'proxima-nova', sans-serif !default;
$blockquote-font-family:    'temeraire', serif !default;
$navbar-font-family:        'proxima-nova', sans-serif !default;
$navbar-brand-font-family:  'proxima-nova', sans-serif !default;
$btn-font-family:           'proxima-nova', sans-serif !default;

$font-size-base: 1.25rem !default; // Assumes the browser default, typically `16px`

$font-weight-base:      400 !default;
$line-height-base:      1.5 !default;

$h1-font-size:      40px !default;
$h2-font-size:      36px !default;
$h3-font-size:      30px !default;
$h4-font-size:      24px !default;
$h5-font-size:      20px !default;
$h6-font-size:      18px !default;

$h1-font-weight:    700 !default;
$h2-font-weight:    700 !default;
$h3-font-weight:    700 !default;
$h4-font-weight:    700 !default;
$h5-font-weight:    700 !default;
$h6-font-weight:    700 !default;

$headings-margin-bottom:    1.5625rem !default;
$headings-line-height:      1.2 !default;
$headings-letter-spacing:   0.12em !default;
$headings-color:            inherit !default;
$headings-text-transform:   uppercase !default;
$headings-font-style:       normal !default;

$display1-size:             90px !default;
$display2-size:             70px !default;
$display3-size:             60px !default;
$display4-size:             54px !default;

$display1-weight:           400 !default;
$display2-weight:           400 !default;
$display3-weight:           400 !default;
$display4-weight:           400 !default;
$display-line-height:       1.1 !default;
$display-text-transform:    initial !default;
$display-font-style:        normal !default;

$lead-font-size:        20px !default;
$lead-font-weight:      300 !default;
$lead-line-height:      1.5 !default;

$small-font-size:           14px !default;
$extra-small-font-size:     11px !default;
$small-line-height:         1.4 !default;

$blockquote-font-size:          22px !default;
$blockquote-font-style:         italic !default;
$blockquote-font-weight:        400 !default;
$blockquote-letter-spacing:     0.02em !default;
$blockquote-line-height:        1.6 !default;

$hr-border-color:       $border-color !default;
$hr-border-width:       1px !default;

$backdrop-color:        rgba($black, 0.75);

$label-font-size:           0.8rem;
$label-line-height:         1.2em;
$label-letter-spacing:      0;
$label-font-weight:         bold;
$label-text-transform:      initial;

$input-placeholder-color:       #26282B;
$input-border-color:            $border-color;
$input-padding-y:               1rem;
$input-padding-x:               1rem;
$input-box-shadow:              none;
$input-border-width:            1px;
$input-border-radius:           0 !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

/*
 * 	Navigation Top Level Items
 */

$nav-font-size:             16px;
$nav-font-weight:           500;
$nav-font-style:            normal;
$nav-text-transform:        uppercase;
$nav-line-height:           1.4em;
$nav-letter-spacing:        0.15em;
$nav-color:                 #586570;
$nav-margin:                0px 15px;
$nav-padding:               5px 0px;

$nav-hover-background:      transparent;
$nav-hover-color:           #586570;
$nav-current-color:         #586570;
$nav-current-background:    transparent;

/*
 * 	Navigation Drop Down Items
 */

$nav-dropdown-container-margin:     0 20px 0 0;
$nav-dropdown-container-padding-y:  10px;
$nav-dropdown-container-padding-x:  10px;
$nav-dropdown-item-padding-y:       0.5rem;
$nav-dropdown-item-padding-x:       1rem;
$nav-dropdown-background:           #4C97D3;
$nav-dropdown-border-radius:        $border-radius;
$nav-dropdown-border:               none;
$nav-dropdown-box-shadow:           $box-shadow;
$nav-dropdown-container-padding:    10px 0px;
$nav-dropdown-item-padding:         10px 10px;
$nav-dropdown-font-family:          $navbar-font-family;
$nav-dropdown-font-size:            14px;
$nav-dropdown-font-weight:          700;
$nav-dropdown-font-style:           normal;
$nav-dropdown-text-transform:       uppercase;
$nav-dropdown-line-height:          1.4em;
$nav-dropdown-letter-spacing:       0.15em;
$nav-dropdown-color:                $white;
$nav-dropdown-second-color:         $white;
$nav-dropdown-third-color:          $white;

$nav-dropdown-hover-background:     #3D7CAE;
$nav-dropdown-hover-color:          $white;
$nav-dropdown-current-background:   #D84B52;
$nav-dropdown-current-color:        $white;
$nav-dropdown-highlighted-color:    $white;

/*
 * 	Mobile Navigation
 */

 $mobile-nav-font-size:             13px;
 $mobile-nav-line-height:           1.4em;
 $mobile-nav-padding:               10px 15px;
 $mobile-current-background:        transparent;
 $mobile-current-color:             $gray-300;

/*
 * 	Buttons
 */

$btn-padding-y:               15px !default;
$btn-padding-x:               20px !default;
$btn-font-size:               16px !default;
$btn-text-transform:          uppercase !default;
$btn-letter-spacing:          0.15em !default;
$btn-line-height:             1.4em !default;
$btn-border-width:            1px !default;
$btn-font-weight:             700 !default;
$btn-box-shadow:              none !default;
$btn-focus-width:             1px !default;
$btn-focus-box-shadow:        none !default;
$btn-active-box-shadow:       none !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$yiq-contrasted-threshold:    175 !default;


$btn-padding-y-lg:            $btn-padding-y * 1.1 !default;
$btn-padding-x-lg:            $btn-padding-x * 1.1 !default;
$btn-font-size-lg:            $btn-font-size !default;

$btn-padding-y-sm:            $btn-padding-y * 0.9 !default;
$btn-padding-x-sm:            $btn-padding-x * 0.9 !default;
$btn-font-size-sm:            $btn-font-size !default;


/*
 * 	Badges
 */

$badge-padding-y:           0 !default;
$badge-padding-x:           0 !default;
$badge-font-size:           12px !default;
$badge-font-style:          normal !default;
$badge-color:               $gray-900 !default;
$badge-font-weight:         700 !default;
$badge-text-transform:      uppercase !default;
$badge-line-height:         1.4em !default;
$badge-letter-spacing:      0.1em !default;
$badge-border-radius:       0 !default;
$badge-background-color:    transparent !default;
$badge-border-width:        0 !default;
$badge-border-color:        transparent !default;

$badge-focus-width:         1px !default;



/*
 * 	Default theme - Owl Carousel CSS File
 */

$owl-color-base:    theme-color("primary") !default;
$owl-color-white:   $white !default;
$owl-color-gray:    $gray-500 !default;

//nav

$owl-nav-color:             rgba($white, 0.75) !default;
$owl-nav-color-hover:       $white !default;
$owl-nav-font-size:         40px !default;
$owl-nav-rounded:           0 !default;
$owl-nav-margin:            0 10px !default;
$owl-nav-padding:           10px !default;
$owl-nav-background:        transparent !default;
$owl-nav-background-hover:  transparent !default;
$owl-nav-disabled-opacity:  0.5 !default;

//dots

$owl-dot-width:                 10px !default;
$owl-dot-height:                10px !default;
$owl-dot-rounded:               100% !default;
$owl-dot-margin:                5px 7px !default;
$owl-dot-background:            rgba(#26282B, 0.25) !default;
$owl-dot-background-active:     #FFFFFF !default;