section.testimonials-section {
    background-color: #4C97D3;
    padding: 100px 0;

&.testimonials {
    blockquote {
        margin: 0 0 2rem;

        p {
            font-family: 'ivypresto-text', serif !important;
            @include media-breakpoint-up(xxl) {
                & {
                    font-size: 27px;
                    line-height: 34px;
                }
            }
            @include media-breakpoint-down(xxl) {
                font-size: 27px;
                line-height: 34px;
            }
        }

        a {
            color: #fff;

            &:hover {
                color: #d9d9d9;
            }
        }

        cite {
            font-family: proxima-nova, sans-serif;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.15em;
            margin-top: 15px;
            margin-bottom: 0;

            &::before {
                content: "-";
                position: relative;
                font-size: 16px;
                margin-right: 10px;
            }
            span {
                letter-spacing: 0.2em !important;
                display: inline-block !important;
            }
            :nth-child(2) {
                display: none !important;
            }
            :nth-child(3) {
                display: none !important;
            }
        }
    }
    .testimonial-wrapper {
        border-right: none !important;
        @media screen and (max-width: 991px) {
            & {
                margin-bottom: 0px !important;
                margin-top: 0 !important;
            }
        }
        @include media-breakpoint-up(xxl) {
            & {
                padding: 25px 0 30px;
            }
        }
        @media screen and (max-width: 1720px) {
            & {
                padding: 25px 0 30px;
            }

        }
        @include media-breakpoint-down(xl) {
            & {
                padding: 25px 0 30px;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 25px 0 30px;
            }
        }

    }
}

.mod {
    margin-left: 100px;
}
    
}